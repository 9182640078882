@import url('https://fonts.googleapis.com/css2?family=Orelega+One&display=swap');

@font-face {
  font-family: 'museo_slab700';
  src: url('fonts/museo_slab_4-webfont.woff2') format('woff2'),
    url('fonts/museo_slab_4-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #F5F6F7;
  font-family: 'Orelega One', cursive;
}

label {
  color: #56535B;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  /* 142.857% */
}

.h-100vh {
  min-height: 100vh;
}

.logo {
  max-width: 150px;
}

.login_area {
  padding-left: 100px;
}

.login_area p {
  color: #56535B;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Orelega One', cursive;
  font-size: 24px;
  font-style: normal;
  line-height: 30px;
  /* 125% */
  text-transform: capitalize;
}

input:focus,
input:active,
input:hover {
  outline: none;
  background-color: #ffffff;
  background: #ffffff;
}

input {
  border-radius: 8px;
  border: 0.8px solid #009299;
  background-color: #FFF;
  box-shadow: 0px 44px 92px 0px rgba(159, 159, 159, 0.25);
  display: flex;
  padding: 10px 14px 10px 15px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  gap: 197px;
  width: 100%;
  font-family: 'museo_slab700';
  font-size: 14px;

}

button:focus,
button:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  border: none;
}

.site_btn button {
  background-color: #009299;
  color: #ffffff;
  border: 1px solid #009299;
  width: 100%;
  height: 50px;
  border-radius: 15px;
}

.search_icon {
  fill: rgba(79, 84, 84, 0.5);
}

.form-group.search_input input {
  padding-left: 45px;
}

span.search_icons {
  position: absolute;
  top: 8px;
  left: 15px;
}

.center_img img {
  width: 75%;
}

.forget a,
.forget button {
  color: #56535B;
  text-decoration: none;
  font-family: 'museo_slab700';
  font-size: 14px;
}

.inner_logo img {
  max-width: 130px;
}

.header {
  padding: 15px;
  border-bottom: 1px solid rgba(86, 83, 91, 0.20);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sideBar {
  border-right: 1px solid rgba(86, 83, 91, 0.20);
}

button {
  border: none;
  outline: none;
}

.profile {
  display: flex;
  align-items: center;
  gap: 15px;
}

.profile img {
  max-width: 35px;
}

.dropdown-toggle {
  color: #56535B;
  font-family: 'museo_slab700';
  font-size: 14px;
  background: transparent;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu-end {
  top: 15px !important;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
  border: none;
}

.norification {
  padding: 20px 15px;
}

.sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 996;
  transition: all 0.3s;
  padding: 0 20px;
  overflow-y: auto;

  scrollbar-color: #aab7cf transparent;
}

main {
  transition: all 0.3s;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

.menu_title {
  color: rgba(86, 83, 91, 0.80);
  font-size: 12px;
  font-family: 'museo_slab700';
  margin-bottom: 24px;
  margin-top: 24px;
}

.sidenav {
  padding: 0 15px;
  list-style: none;
}

.sidenav .nav-item {
  border-radius: 3px;
  color: #56535B;
  font-family: 'museo_slab700';
  font-size: 15px;
  font-style: normal;
  line-height: normal;
  transition: 0.3s;
}

.sidenav .nav-item.active {
  color: #009299;
  box-shadow: -2px 0px 0px 0px #009299;
  background: rgba(0, 146, 153, 0.10);
  padding: 15px;

}

.notfound {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.filter-btn {
  background-color: #009299;
  padding: 10px 20px;
  border-radius: 10px;
  color: white;
}

.filter-dropdown li {
  width: 100%;
}

.filter-dropdown li button {
  width: 100% !important;
  padding: 9px;
  background-color: transparent;
  margin-bottom: 0px;
}

.pagination {
  display: flex;
  list-style-type: none;
  margin: auto;
  gap: 25px !important;
}

/* .sidenav .nav-item:hover {
  color: #009299;
  box-shadow: -2px 0px 0px 0px #009299;
  background: rgba(0, 146, 153,0.10);
  padding: 15px;

} */

.delete-btn {
  background-color: white !important;
  color: #FF4B26 !important;
  font-size: 12px;
  padding: 0px !important;
  position: absolute;
  margin-top: 10px;
  height: 30px;
  margin-left: 10px;
  border-radius: 5px;
  border-radius: 50% !important;
  width: 30px;
}

.sidenav .nav-item a {
  border-radius: 3px;
  color: #56535B;
  font-family: 'museo_slab700';
  font-size: 15px;
  font-style: normal;
  line-height: normal;
  padding: 15px 0;
  margin: 15px 0;
  transition: 0.3s;
}

.sidenav .nav-item a.active {
  color: #009299;
  box-shadow: -2px 0px 0px 0px #009299;
  background: rgba(0, 146, 153, 0.10);
  padding: 15px;
}

.sidenav .nav-item a:hover {
  color: #009299;
  box-shadow: -2px 0px 0px 0px #009299;
  background: rgba(0, 146, 153, 0.10);
  padding: 15px;
}

.sidenav .nav-item span {
  padding-right: 10px;
}

.main {
  margin-left: 300px;
}

.edit_btn {
  color: #009299;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  font-family: 'museo_slab700';
  border-radius: 4px;
  background: rgba(0, 146, 153, 0.10);
  padding: 10px;
  gap: 5px;
}

.sidetoggle .main {
  margin-left: 0;
}

.sidetoggle .sidebar {
  margin-left: -300px;
}

.toggle_logo {
  display: flex;
  align-items: center;
  gap: 15px;
}

.color-green {
  color: green !important;
}

.color-red {
  color: red !important;
}

.setBorder {
  position: relative;
}

.setBorder::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  left: 0px;
  background: rgb(0 0 0 / 15%);
  top: 0;
}

.setBorder::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  right: 0px;
  background: rgb(0 0 0 / 15%);
  top: 0;
}

.all_comp {
  padding: 20px 30px;
  padding-top: 75px;
}

.pagetitle {
  color: #56535B;
  font-family: 'Orelega One', cursive;
  font-size: 24px;
  font-style: normal;
  line-height: 20px;
}

.pagetitle button {
  color: #56535B;
  font-family: 'Orelega One', cursive;
  font-size: 24px;
  font-style: normal;
  line-height: 20px;
  background-color: transparent;
  gap: 10px;
}

.card {
  border-radius: 16px;
  background: #E3F5FF;
  border: 0;
}

.card-title {
  color: #56535B;
  font-family: 'Orelega One', cursive;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.card .numbers {
  font-size: 24px;
  color: #56535B;
  font-family: 'museo_slab700';
  font-style: normal;
  line-height: 36px;
}

.card .graph {
  font-size: 12px;
  color: #56535B;
  font-family: 'museo_slab700';
  font-style: normal;
  line-height: 20px;
}

.card-body {
  padding: 20px 30px;
}

.notify_title {
  color: #56535B;
  font-family: 'Orelega One', cursive;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
}

.notification_list {
  padding: 0;
  list-style: none;
  margin-top: 20px;
}

.n_item {
  color: #56535B;
  font-size: 14px;
  font-style: normal;
  line-height: 17px;
  padding-bottom: 10px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.n_time {
  color: #56535B;
  font-family: 'museo_slab700';
  font-size: 10px;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;

}

.norification {
  padding: 20px 30px;
}

.notification_list li {
  border-bottom: 1px solid rgb(0 0 0 / 15%);
  padding: 10px 0 20px 0;
}

.table th {
  color: #584278;
  font-family: 'Orelega One', cursive;
  font-size: 14px;
  line-height: normal;
  text-transform: capitalize;
  font-weight: 400;
}

.table thead {
  border-radius: 0px 0px 16px 16px;
  --bs-table-bg: rgba(0, 146, 153, 0.10);
}

.table>:not(caption)>*>* {
  padding: 20px 15px;
}

.table_profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.table tr td {
  padding: 15px;
}

.table_profile img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.table {
  border-radius: 15px;
  overflow: hidden;
}

.tname,
td {
  color: #56535B !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.26px;
  text-transform: capitalize;
  font-family: 'museo_slab700';
  vertical-align: middle;
}

.actions .btn {
  margin: 0 2px;
  padding: 0;
}

.actions .view {
  color: #56535B;
}

.actions .block {
  color: #0F993E;
}

.actions .delete {
  color: #FF4B26;
}

.actions button {
  background-color: transparent;
}

.outer_div {
  border-radius: 16px;
  border: 1px solid rgba(86, 83, 91, 0.20);
  background: #FFF;
  padding: 20px;
}

.profile_image img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.profile_image input {
  height: 0px;
  width: 0px;
}

.sidebar-profile {
  height: 35px;
  width: 35px;
}

.edit_profile {
  width: 50%;
}

.edit_profile li {
  margin-bottom: 0px !important;
}

.small_heading {
  color: #56535B;
  font-family: 'museo_slab700';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  opacity: 0.6;
}

.name {
  color: #56535B !important;
  font-family: 'museo_slab700' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  justify-content: start !important;
  text-transform: capitalize;
}

.profile_list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.profile_list li {
  margin-bottom: 30px;
}

.card_outer {
  border-radius: 10px;
  border: 1px solid rgb(86 83 91 / 0.20);
  background: #FFF;
  padding: 15px;
}

.service_name {
  color: #584278;
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  text-transform: capitalize;
  font-family: 'museo_slab700';
}

.card_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service_status {
  font-family: 'museo_slab700';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding: 10px 0;
}

.card_outer .name {
  padding: 10px 0;
}

.card_outer .small_heading {
  padding: 2px 0;
}

.sub_title {
  color: #56535B;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  font-family: 'Orelega One', cursive;
  text-transform: capitalize;
}

.complete {
  color: #0F993E;
}

.cancel {
  color: #FF4B26;
}

.border-bottom {
  margin-bottom: 40px;
}

.blank_btn {
  background-color: transparent;
}

.property_description {
  list-style-type: none;
}

.ql-editor {
  height: 200px;
  max-height: 200px;
}

.left_col button {
  background-color: #009299;
  color: #ffffff;
  font-size: 12px;
  padding: 5px 20px;
  margin-top: 10px;
  border-radius: 5px;
}

.property-card{
  width: 100%;
}

.property_not_found {
  text-align: center;
  padding-top: 20px;
}

.property_not_found img {
  max-width: 250px;
}

.left_col img {
  height: 180px;
  width: 100%;
  object-fit: cover;
}


.btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  font-size: 14px;
}

.modal_icon svg {
  width: 30px;
  height: 30px;
}

.modal_msg {
  color: #56535B;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  font-family: 'Orelega One';
  margin-top: 20px;
}

.modal_btns {
  margin-top: 30px;
}

.modal-body {
  padding-top: 25px;
  padding-bottom: 25px;
}

.modal_btns {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.modal_btns button {
  border-radius: 11px;
  background: transparent;
  text-align: center;
  font-size: 14px;
  color: #56535B;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  min-height: 36px;
  min-width: 112px;
  font-family: 'Orelega One';
  line-height: 30px;
}

.modal_btns .purple {
  background: #009299;
  color: #fff;
}

.red {
  color: #FF4B26;
}

.green {
  color: #0F993E;
}

.create-admin a {
  padding: 10px 20px;
  border: 2px solid #009299;
  border-radius: 5px;
  background-color: transparent;
  color: #009299;
  text-decoration: none;
}

.form-check-input:checked {
  background-color: #009299;
  border-color: #009299;
}

.styled-checkbox:checked+label:after {
  background: #fff;
  box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
  content: "";
  height: 2px;
  left: 5px;
  position: absolute;
  top: 9px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 2px;
}

.broken-line {
  white-space: pre-wrap;
  line-height: 28px;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.checkboxes label {
  align-items: center;
  display: flex;
}

.styled-checkbox+label:before {
  background: transparent;
  content: "";
  display: inline-block;
  height: 20px;
  margin-right: 10px;
  vertical-align: text-top;
  width: 20px;
}

.styled-checkbox+label:before,
.styled-checkbox:focus+label:before {
  border: 1px solid rgba(86, 83, 91, .3);
  border-radius: 5px;
}

.styled-checkbox:checked+label:after {
  background: #fff;
  box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
  content: "";
  height: 2px;
  left: 5px;
  position: absolute;
  top: 9px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 2px;
}

.form-check-inline {
  position: relative;
}

.styled-checkbox:checked+label:before,
.styled-checkbox:focus+label:before {
  background: #009299;
}