@media  screen and (max-width:992px) {
    .login_area{
        padding: 0;
    }
}
@media  screen and (max-width:768px) {
    .login_area{
        padding: 0;
    }
    .mobile_hide{
        display: none;
    }
    .edit_profile{
        width: 100%;
    }
}